import React, { useReducer, useContext } from 'react';
import 'firebase/auth';
import AuthContext from './authContext';
import authReducer from './authReducer';
import { auth } from '../../config/config';
import { signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import alertContext from '../alert/alertContext';

const AuthState = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    smallLoading: false,
    isAuthenticated: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const loginUser = async (data) => {
    const { email, password, rememberMe } = data;

    dispatch({ type: 'SET_SMALL_LOADING', payload: true });

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Update user profile (optional)
      await updateProfile(userCredential.user);

      // Handle "Remember Me" functionality
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }
    } catch (error) {
      dispatch({ type: 'SET_SMALL_LOADING', payload: false });
      // Handle specific error cases
      if (error.code === 'auth/invalid-login-credentials') {
        setAlert(
          'Pogrešan password ili Email',
          'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
        );
      } else if (error.code === 'auth/too-many-requests') {
        setAlert(
          'Previše puta ukucan pogrešan password',
          'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
        );
      } else if (error.code === 'auth/user-not-found') {
        setAlert(
          'Korisnik nije pronađen',
          'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
        );
      } else if (error.code === 'unavailable' || error.code === 'auth/network-request-failed') {
        setAlert(
          'Nema internetske veze. Molimo provjerite vašu internet konekciju.',
          'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
        );
      } 
      return null;
    } finally {
      dispatch({ type: 'SET_SMALL_LOADING', payload: false });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        isAuthenticated: state.isAuthenticated,
        smallLoading: state.smallLoading,
        dispatch,
        loginUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
