import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className='flex flex-col items-center justify-center h-[86vh] bg-gray-100 text-center'>
      <h1 className='text-4xl font-bold text-red-600'>
        404 - Stranica nije pronađena
      </h1>
      <p className='text-xl text-gray-600 mt-4'>
        Ups! Stranica koju tražite ne postoji.
      </p>
      <Link to='/' className='mt-6 text-lg text-blue-500 hover:underline'>
        Vrati se na početnu stranicu
      </Link>
    </div>
  );
};

export default NotFoundPage;
