import React, { useContext, useEffect } from 'react';

import ZaposleniciTable from '../components/ZaposleniciTable';
import authContext from '../contexts/auth/authContext';
import { auth } from '../config/config';
import globalContext from '../contexts/satnica/globalContext';
import alertContext from '../contexts/alert/alertContext';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import NotificationIcon from '../icons/NotificationIcon';
import ClockIcon from '../icons/ClockIcon';

const DashBoard = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);
  const AlertContext = useContext(alertContext);
  const { user } = AuthContext;
  const { setAlert } = AlertContext;

  const {
    displayEmployees,
    dispatch,
    getCompanyDetails,
    companyDetails,
    employees,
  } = GlobalContext;

  const { companyName, subscriptionStatus } = companyDetails;

  const { message, subscriptionActive, remainingTrialDays, isPaidUser } =
    subscriptionStatus || {};

  const currentUserUid = auth.currentUser.uid;

  // Display users from firebase
  useEffect(() => {
    if (user) {
      displayEmployees(currentUserUid, dispatch);
      getCompanyDetails(currentUserUid, dispatch, setAlert);
      dispatch({ type: 'CLEAR_EMPLOYEE' });
    }
    //eslint-disable-next-line
  }, [currentUserUid]);

  const formattedMessage = message?.split(/(?<=[.,])\s*/);

  return (
    <div className='mb-10'>
      {companyName && (
        <div className='flex justify-center mt-3 mb-5 text-white roboto'>
          <h2 style={{ fontSize: '25px' }} className='block sm:hidden'>
            {companyName}
          </h2>
        </div>
      )}
      {companyName && !subscriptionActive && (
        <div
          className='flex zaposlenici-container justify-end'
          style={{
            justifyContent:
              remainingTrialDays === 0 && !isPaidUser ? 'centar' : '',
          }}
        >
          <div
            className={`flex gap-1 left-[-17px] lg:left-[0] mb-0 relative md:mb-5 notification`}
          >
            <div className=''>
              <ClockIcon />
            </div>
            <div className='flex items-center'>
              <div
                className='rounded-xl flex items-center bg-white px-2 py-1 text-sm'
                style={{ height: 'min-content' }}
              >
                <NotificationIcon />

                <p className='bg-white inline-block py-1 px-2 text-red-700 font-semibold text-sm roboto'>
                  {formattedMessage?.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < formattedMessage.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {remainingTrialDays === 0 && !isPaidUser && (
        <div className='custom-container pt-10 roboto text-gray-200 zaposlenici-container text-center p-5 notification-message'>
          <p className='font-semibold w-75 text-lg '>
            Ako se odlučite za pretplatu, molimo vas da nam dostavite
            informacije o vašoj firmi. Informacije možete poslati putem naše{' '}
            <Link to='/kontakt' className='pb-1 text-blue-200'>
              kontakt forme
            </Link>{' '}
            ili nam pišite na{' '}
            <a href='mailto:info@satnica.net' className='text-blue-200'>
              info@satnica.net
            </a>
            , kako bismo vam mogli pripremiti ponudu i ugovor.
          </p>
          <p className='font-semibold w-75 text-lg mt-2'>
            Za sve dodatne informacije ili pitanja, možete nas kontaktirati i
            putem telefona na broj{' '}
            <a href='tel:+38761056710' className='text-blue-200'>
              +387 61 056 710
            </a>
            .
          </p>
        </div>
      )}

      {companyName && employees.length === 0 && (
        <div className='p-3'>
          <div className=''>
            <h2 className='text-3xl text-gray-300 mt-10 mb-3'>
              Dobro došli na Satnicu
            </h2>

            <p className='text-gray-300'>
              Dodajte prvog zaposlenika kako bi se prikazala tabela.
            </p>
          </div>
          <Link to='/user'>
            <div
              className={`bg-gray-300 text-gray-900 p-2 px-4 mt-10 rounded-full inline-block hover:bg-blue-500 hover:text-gray-100 items-center text-sm ${
                employees?.length === 0 ? 'blinking-button' : ''
              }`}
            >
              <i className='fa-sharp fa-solid fa-user-plus'></i>
              <span className='font-semibold'>Dodaj zaposlenika</span>
            </div>
          </Link>
        </div>
      )}
      {!companyName ? (
        <div className='pl-5'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='flex zaposlenici-container'>
            {remainingTrialDays > 0 || isPaidUser ? <ZaposleniciTable /> : null}
          </div>
        </>
      )}
    </div>
  );
};

export default DashBoard;
