import React, { useState, useContext } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import bs from 'date-fns/locale/bs'; // Import Bosnian locale

import 'react-datepicker/dist/react-datepicker.css'; // Import CSS

import * as yup from 'yup';
import { useFormik } from 'formik';
import WeekDayTable from '../components/WeekDayTable';
import { useEffect } from 'react';
import { auth } from '../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import globalContext from '../contexts/satnica/globalContext';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../components/Loader';
import alertContext from '../contexts/alert/alertContext';
import persons from '../assets/perons.png';
import address from '../assets/address.png';
import data from '../assets/data.png';
import gender from '../assets/gender.png';
import date from '../assets/date.png';
import grupa from '../assets/radnici.png';
import { isValid, parseISO } from 'date-fns';

const inputStyle =
  'appearance-none  w-[15rem] px-3  border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 text-sm p-1 ';

const CreateEditPage = () => {
  const [todayDate, setTodayDate] = useState('');
  const [startWorkDate, setStartWorkDate] = useState('');
  const [endWorkDate, setEndWorkDate] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const [loading, setLoading] = useState(false);

  const [workingDaysOfMonth, setWorkingDaysOfMonth] = useState([
    {
      month: '',
      workingHours: [],
    },
  ]);

  const [workingMonthsInYear, setWorkingMonthsInYear] = useState([{}]);

  const param = useParams();

  const { employeeId } = param;

  // Register the locale
  registerLocale('bs', bs);

  useEffect(() => {
    setTodayDate(moment().format('YYYY-MM-DD'));
    dispatch({ type: 'DISPLAY_EMPLOYEE', payload: employee });
    setLoading(false);
    //eslint-disable-next-line
  }, []);

  const firstDayOfPreviousMonth = moment()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');

  const lastDayOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');

  const navigate = useNavigate();

  const GlobalContext = useContext(globalContext);

  const { addEmployee, dispatch, employee, updateEmployee } = GlobalContext;

  const currentUserUid = auth.currentUser.uid;

  const formik = useFormik({
    initialValues: {
      firstName: employee.firstName ?? '',
      lastName: employee.lastName ?? '',
      gender: employee.gender ?? 'muško',
      department: employee.department ?? '',
      address: employee.address ?? '',
      startDate: employee.startDate ?? '',
      endDate: employee.endDate ?? '',
      group: employee.group ?? 'radnici',
      weekShift: employee.weekShift ?? '',
    },

    validationSchema: yup.object().shape({
      firstName: yup.string().required('Ime je obavezno'),
      lastName: yup.string().required('Prezime je obavezno'),
      gender: yup.string(),
      department: yup.string(),
      address: yup.string(),
      startDate: yup.string().required('Početak rada je obavezan'),
      endDate: yup.string(),
      group: yup.string(),
      weekShift: yup
        .number()
        .required('Unesite broj sati')
        .positive('Mora biti pozitivan')
        .typeError('Unesite broj'),
    }),

    onSubmit: async (values) => {
      setLoading(true);

      // Set endDate based on selectedOptions

      const dateMy = moment(values.startDate).format('YYYY-MM-DD');

      const finalValues = {
        ...values,
        startDate: dateMy,
        endDate: selectedOptions.length > 0 ? values.endDate : '',
      };
      try {
        if (employee && employeeId) {
          await updateEmployee(
            employeeId,
            finalValues,
            currentUserUid,
            navigate,
            setLoading,
            setAlert
          );
        } else {
          await addEmployee(
            values,
            workingMonthsInYear,
            currentUserUid,
            navigate,
            setAlert
          );
        }
      } catch (error) {
        setLoading(false);
        throw error; // Let the error propagate to updateEmployee's catch block
      } finally {
        setLoading(false); // Reset loading to false after API calls
      }
    },
  });

  const handleOptionChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOptions([value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    formik.handleChange(event); // call Formik's handleChange function to update the formik.values object
    setStartWorkDate(new Date(date));
  };

  const handleEndDate = (event) => {
    const date = event.target.value;
    formik.handleChange(event); // call Formik's handleChange function to update the formik.values object
    setEndWorkDate(new Date(date));
  };

  const errorStyle = {
    color: '#FF0000',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '5px',
  };

  useEffect(() => {
    if (employee && employee?.endDate !== '') {
      setSelectedOptions(['kraj evidencije']);
    } else {
      setEndWorkDate('');
      setSelectedOptions([]);
    }
  }, []);

  const lastWorkingMonth =
    employee?.workingMonths?.[employee.workingMonths.length - 1]?.month;

  // Format the last working month to YYYY-MM-DD using Moment.js
  const maxDate = lastWorkingMonth
    ? moment(lastWorkingMonth).startOf('month').format('YYYY-MM-DD')
    : moment(lastWorkingMonth).format('YYYY-MM-DD');

  return (
    <div className='mb-10 sm:p-4 px-4'>
      <h3 className='my-3 font-semibold text-gray-300 text-xl '>
        {employeeId ? 'Ažuriraj Zaposlenika' : 'Dodaj Zaposlenika'}
      </h3>

      <div className='custom-container '>
        <form
          className='mt-5 p-2 create-edit-form'
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h3 className='rounded-lg border border-gray-300 shadow-sm overflow-hidden'>
              Osnovni podatci
            </h3>
            <div className='form-data'>
              <div className='form-control'>
                <img src={persons} alt='' className='hidden sm:block' />
                <div className='form-item w-100'>
                  <label htmlFor=''>Ime *</label>
                  <input
                    type='text'
                    className='w-100'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div style={errorStyle}>
                    {formik.touched.firstName &&
                      formik.errors.firstName &&
                      formik.errors.firstName}
                  </div>
                </div>
              </div>
              <div className='form-control'>
                <img src={persons} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor=''>Prezime*</label>
                  <input
                    type='text'
                    name='lastName'
                    className={inputStyle}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    onBlur={formik.handleBlur}
                  />

                  <div style={errorStyle}>
                    {formik.touched.lastName &&
                      formik.errors.lastName &&
                      formik.errors.lastName}
                  </div>
                </div>
              </div>
              <div className='form-control'>
                <img src={address} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor=''>Adresa</label>
                  <input
                    type='text'
                    name='address'
                    className={inputStyle}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className='form-control'>
                <img src={data} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor=''>Radno mjesto</label>
                  <input
                    type='text'
                    name='department'
                    className={inputStyle}
                    value={formik.values.department}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className='form-control'>
                <img src={gender} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor='' className='pl-4'>
                    Spol
                  </label>
                  <select
                    name='gender'
                    className='p-1 px-3 text-sm w-[100px] block'
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                  >
                    <option defaultValue='muško'>muško</option>
                    <option value='žensko'>žensko</option>
                  </select>
                </div>
              </div>
              <div className='form-control'>
                <img src={date} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor='' className=''>
                    Početak evidencije *
                  </label>
                  {/* {
                    <input
                      type='date'
                      name='startDate'
                      min={firstDayOfPreviousMonth}
                      max={todayDate}
                      locale='bs-BA'
                      disabled={employeeId ? true : false}
                      className={inputStyle}
                      onChange={handleDateChange}
                      value={formik.values.startDate}
                      onBlur={formik.handleBlur}
                    />
                  } */}
                  <div className='custom-datapicker'>
                    <DatePicker
                      type='date'
                      name='startDate'
                      selected={
                        formik.values.startDate
                          ? new Date(formik.values.startDate)
                          : null
                      }
                      onChange={(date) => {
                        // Format the date before setting it in formik values
                        const formattedDate = moment(date).format('YYYY-MM-DD');
                        formik.setFieldValue('startDate', formattedDate); // Save formatted date
                        setStartWorkDate(new Date(date));
                      }}
                      onBlur={formik.handleBlur}
                      minDate={new Date(firstDayOfPreviousMonth)}
                      maxDate={new Date(todayDate)}
                      locale='bs'
                      dateFormat='dd.MM.yyyy'
                      placeholderText='Odaberite datum'
                      disabled={!!employeeId}
                      className={inputStyle}
                    />
                  </div>
                </div>
              </div>
              <div className='form-control'>
                <img src={grupa} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor='' className='pl-4'>
                    Grupa
                  </label>
                  <select
                    name='group'
                    className='p-1 text-sm w-[12rem] px-3'
                    onChange={formik.handleChange}
                    defaultValue={formik.values.group}
                    onBlur={formik.handleBlur}
                  >
                    <option defaultValue='radnici'>radnici</option>

                    <option value='studenti'>studenti</option>
                    <option value='stručno osposobljavanje'>
                      stručno osposobljavanje
                    </option>
                  </select>
                </div>
              </div>
              <div className='form-control'>
                <img src={date} alt='' className='hidden sm:block' />
                <div className='form-item'>
                  <label htmlFor='' className=''>
                    Sedmična satnica*
                  </label>
                  <input
                    type='number'
                    inputMode='numeric'
                    name='weekShift'
                    className={inputStyle + 'w-[150px]'}
                    onChange={formik.handleChange}
                    defaultValue={formik.values.weekShift}
                    onBlur={formik.handleBlur}
                  />

                  <div style={errorStyle}>
                    {formik.touched.weekShift &&
                      formik.errors.weekShift &&
                      formik.errors.weekShift}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='my-5'>
            <h3>Radno Vrijeme</h3>
          </div>

          <div className='form-date'>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
              }}
            >
              {!employeeId && (
                <WeekDayTable
                  inputStyle={inputStyle}
                  month={startWorkDate}
                  setMonth={setStartWorkDate}
                  setWorkingDaysOfMonth={setWorkingDaysOfMonth}
                  setWorkingMonthsInYear={setWorkingMonthsInYear}
                  workingMonthsInYear={workingMonthsInYear}
                />
              )}
              {employeeId && (
                <div className='mt-5'>
                  <div class='checkbox-wrapper-24 mx-2'>
                    <input
                      className='mr-2  text-sm'
                      id='check-24'
                      name='check'
                      type='checkbox'
                      value='kraj evidencije'
                      checked={selectedOptions.includes('kraj evidencije')}
                      onChange={handleOptionChange}
                    />
                    <label htmlFor='check-24'>
                      <span></span> Zaključi radnika
                    </label>
                  </div>
                  {selectedOptions.includes('kraj evidencije') && (
                    <div className='flex items-start mb-3 mt-4'>
                      <div className='form-control flex items-start gap-2'>
                        <img src={date} className='hidden md:block' alt='' />
                        <div className='form-item flex flex-col'>
                          <label className=''>Kraj Evidencije</label>
                          {/* {
                            <input
                              style={{ width: '180px' }}
                              type='date'
                              name='endDate'
                              min={
                                employee.startDate < firstDayOfPreviousMonth
                                  ? firstDayOfPreviousMonth
                                  : employee.startDate
                              }
                              max={maxDate}
                              locale='bs-BA'
                              className={inputStyle}
                              onChange={handleEndDate}
                              value={formik.values.endDate}
                              onBlur={formik.handleBlur}
                            />
                          } */}
                          <DatePicker
                            type='date'
                            name='endDate'
                            selected={
                              formik.values.endDate
                                ? isValid(new Date(formik.values.endDate))
                                  ? new Date(formik.values.endDate)
                                  : parseISO(formik.values.endDate)
                                : null
                            }
                            onChange={(date) => {
                              const formattedDate =
                                moment(date).format('YYYY-MM-DD');
                              formik.setFieldValue('endDate', formattedDate); // Save as ISO string
                            }}
                            onBlur={formik.handleBlur}
                            minDate={
                              isValid(new Date(employee.startDate))
                                ? new Date(employee.startDate) <
                                  new Date(firstDayOfPreviousMonth)
                                  ? new Date(firstDayOfPreviousMonth)
                                  : new Date(employee.startDate)
                                : new Date(firstDayOfPreviousMonth)
                            }
                            locale='bs'
                            dateFormat='dd.MM.yyyy'
                            placeholderText='Odaberite datum'
                            className={inputStyle}
                          />
                          <div style={errorStyle}>
                            {formik.touched.endDate &&
                              formik.errors.endDate &&
                              formik.errors.endDate}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='flex items-center mt-4'>
            <button
              className={`mt-2 bg-blue-500 p-2 px-4 rounded-full text-gray-100 hover:bg-blue-600 flex items-center text-sm font-semibold ${
                loading ? `opacity-70` : ''
              }`}
              type='submit'
              disabled={loading}
            >
              {employeeId ? 'Ažuriraj Zaposlenika' : 'Dodaj Zaposlenika'}
            </button>
            <div className='mt-4 ml-4'>{loading && <Loader />}</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEditPage;
